import React from "react"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import Seo from "../../components/seo"
import Layout from "../../app/layout"

import { Breadcrumb } from "antd"

import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"

import ScrollAnimation from "react-animate-on-scroll"

const OrgStructurePage = ({
  data: { aboutOrganizationStructures: data = {} },
}) => {
  const { t } = useI18next()

  return (
    <Layout module_name="about" page_name="organization-structures">
      <Seo
        title={data.title}
        module_name="about"
        page_name="organization-structures"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{data.title}</h1>
          </ScrollAnimation>

          <div className="hide-mobile">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <img src={data.image} alt="LPDP" width="100%" />
              <div className="text-center" style={{ marginTop: 30 }}>
                <div dangerouslySetInnerHTML={{ __html: data.text_note }} />
              </div>
            </ScrollAnimation>
          </div>

          <div className="show-mobile">
            <img
              src={data.mobile_image}
              // srcSet={`${posts.mobile_image} 2x`}
              alt="LPDP"
              className="Struktur Organisasi"
              width="100%"
            />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default OrgStructurePage

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    aboutOrganizationStructures(lang: { eq: $language }) {
      image
      mobile_image
      slug
      title
      text_note
    }
  }
`
